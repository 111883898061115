"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
// 静态配置
var Config = /** @class */function () {
    function Config() {}
    Config.WC_HOST = "https://app.onlinedo.cn";
    Config.WM_HOST = "http://tp6.callmysoft.ceshi.lasdth.com/";
    //验签key
    Config.Wc_key = "MzqTiAXD0aVeEmi7D7N5ckBvDS4U";
    // 登录接口
    Config.WM_LOGIN_GETWECHATCODE = '/index/UnifiedInterface/WxPcUserLogin'; //获取微信登录二维码
    Config.WM_WECHAT_POLLING = '/index/UnifiedInterface/wechat_login'; //微信二维码轮询
    Config.WM_GETPHONECODE = '/index/UnifiedInterface/get_phone_code'; //获取手机登录验证码
    Config.WM_PHONE_LOGIN = '/index/UnifiedInterface/phone_login'; //手机号登录
    Config.WM_GETEMAILCODE = '/index/UnifiedInterface/get_email_code'; //获取邮箱验证码
    Config.WM_EMAIL_LOGIN = '/index/UnifiedInterface/email_login'; //邮箱号登录
    Config.WM_QQ_LOGIN = '/index/ProductCenter/QqLogin'; //qq登录
    Config.WM_QQ_POLLING = '/index/UnifiedInterface/qq_login'; //qq登录轮询
    // 公共顶部
    Config.WM_LOGINOUT = '/index/UnifiedInterface/loginout'; //退出登录
    // 个人中心接口
    Config.WM_USEREQUEST = "/index/CommunityApi";
    Config.WM_UPDATEHEADIMG = '/index/UnifiedInterface/set_user_info'; //修改头像
    Config.WM_BINDACCOUT = '/index/UnifiedInterface/binding_account'; //绑定账号
    Config.WM_BINDSURE = '/index/UnifiedInterface/vecode_confirm'; //确定绑定，下一步
    Config.WM_BINDWECHATPOLLING = '/index/UnifiedInterface/qrcode_confirm'; //绑定微信轮询
    Config.WM_BINDQQ = '/index/ProductCenter/RelatedQQ'; //qq绑定(pc和移动端地址不一样)
    Config.WM_BINDQQPOLLING = '/index/UnifiedInterface/related_qq_confirm'; //qq绑定轮询
    Config.WM_DELETEWECHAT = '/index/UnifiedInterface/delete_wx_code'; //微信注销
    Config.WM_DELETEWECHATPOLLING = '/index/UnifiedInterface/delete_wx_code_confirm'; //微信注销轮询
    Config.WM_DELETEPHONE = '/index/UnifiedInterface/delete_phone_code'; //手机号注销获取验证码
    Config.WM_DELETEPHONECONFIRM = '/index/UnifiedInterface/delete_phone_confirm'; //手机号注销确认
    Config.WM_DELETEEMAIL = '/index/UnifiedInterface/delete_emali_code'; //邮箱号注销
    Config.WM_DELETEEMAILCONFIRM = '/index/UnifiedInterface/delete_email_confirm'; //邮箱号注销确认
    Config.WM_DELETEQQ = '/index/ProductCenter/DeleteQQ?Logout_object=3'; //QQ号注销(pc和移动端地址不一样)
    Config.WM_DELETEQQPOLLING = '/index/UnifiedInterface/delete_qq_confirm'; //QQ好注销轮询
    Config.WM_DOWN_EXCHANGECODE = '/index/UnifiedInterface/get_order_discount_code'; //下载兑换码
    // 搜索结果页
    Config.WM_COLLECTPOST = '/index/ProductCenter/like'; //收藏帖子
    Config.WM_GETARTICLELIST = '/articleList'; //获取教程列表
    Config.WM_GETSEARCHRESULT = '/searchResultApi'; //获取搜索结果页列表
    //提示内容
    Config.Wm_tips_phone_empty = "手机号为空";
    Config.Wm_tips_phone_error = "手机号格式错误";
    Config.Wm_tips_email_empty = "邮箱号为空";
    Config.Wm_tips_email_error = "邮箱号格式错误";
    Config.Wm_tips_code_empty = "验证码为空";
    Config.Wm_tips_getCode = "请先获取验证码";
    // 活动页
    Config.WM_ACTIVITYGETQRCODE = '/index/UnifiedInterface/get_merge_code'; // 获取支付二维码
    Config.WM_ACTIVITYPOLLING = '/index/UnifiedInterface/pay_polling'; // 二维码轮询
    Config.WM_ACTIVITYLOGOUT = '/index/UnifiedInterface/loginout'; // 退出登录
    // 按钮统计接口
    Config.WM_DOWNLOADBUTTONRECORD = '/index/UnifiedInterface/downloadButtonRecord'; // 统计下载
    return Config;
}();
exports.default = Config;