'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterMain = /** @class */function () {
    function FooterMain() {
        this.footerDom = document.getElementById('kingshiper-footer');
        this.serviceDom = document.getElementById('kingshiper-service');
        this.init();
    }
    FooterMain.prototype.init = function () {
        this.footerEvent();
        this.serviceEvent();
        this.closeActivityEntry();
        this.qqEvent();
    };
    // 底部事件
    FooterMain.prototype.footerEvent = function () {
        var kefuBtns = _tool2.default.$_selectAll(this.footerDom, '.item-link-btn');
        kefuBtns.forEach(function (item) {
            item.addEventListener('click', function () {
                window.open(ysf('url'), "_blank");
            });
        });
    };
    // qq群点击事件
    FooterMain.prototype.qqEvent = function () {
        var _loop_1 = function _loop_1(index) {
            var qqAllDom = document.querySelector(".go-url-".concat(index));
            qqAllDom.addEventListener('click', function () {
                var url = qqAllDom.getAttribute('data-dUrl');
                window.open("".concat(url), "_blank");
            });
        };
        for (var index = 0; index < 3; index++) {
            _loop_1(index);
        }
    };
    FooterMain.prototype.serviceEvent = function () {
        var toTopBtn = _tool2.default.$_select(this.serviceDom, '.to-top-btn');
        toTopBtn.addEventListener('click', function () {
            window.scrollTo({
                left: 0,
                top: 0,
                behavior: "smooth"
            });
        });
    };
    // 关闭活动页悬浮入口
    FooterMain.prototype.closeActivityEntry = function () {
        var hasActivityEntry = document.body.contains(document.getElementsByClassName('goto-activity-box')[0]);
        if (hasActivityEntry) {
            var closeActivityBtn = document.querySelector('.close-activity');
            closeActivityBtn.addEventListener('click', function (e) {
                e.preventDefault();
                var ActivityEntryBox = document.querySelector('.goto-activity-box');
                ActivityEntryBox.classList.add('hide');
            });
        }
    };
    return FooterMain;
}();
exports.default = FooterMain;