"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
var WmRequest = /** @class */function () {
    function WmRequest() {}
    // 类型的约束
    WmRequest.prototype.formatUrl = function (json) {
        var dataArr = [];
        // json.t = Math.random();
        for (var key in json) {
            //遍历数组
            dataArr.push("".concat(key, "=").concat(encodeURIComponent(json[key])));
            //encodeURIComponent() 函数可把字符串作为 URI 组件进行编码。
        }
        return dataArr.join('&'); // 改写为需要的格式
    };
    WmRequest.prototype.ajax = function (options) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!options.url) {
                return;
            }
            ; // 可能用 空对象 as any
            options.type = options.type || 'GET';
            options.data = options.data || {};
            options.timeout = options.timeout || 10000;
            var dataToUrlstr = _this.formatUrl(options.data);
            var timer;
            var onStatechange = function onStatechange() {
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        clearTimeout(timer); // 超时取消
                        if (xhr.status >= 200 && xhr.status < 300 || xhr.status === 304) {
                            resolve(JSON.parse(xhr.responseText));
                        } else {
                            reject(xhr.status);
                        }
                    }
                };
            };
            // 1.创建 
            var xhr;
            if (window.XMLHttpRequest) {
                xhr = new XMLHttpRequest();
            } else {
                xhr = new ActiveXObject('Microsoft.XMLHTTP');
                // newObj = new ActiveXObject( servername.typename[, location])
                // ActiveXObject 对象语法有这些部分：其中newObj是必选项。要赋值为 ActiveXObject 的变量名。
                // 1. servername是必选项。提供该对象的应用程序的名称。
                // 1. typename是必选项。要创建的对象的类型或类。
                // 1. location是可选项。创建该对象的网络服务器的名称。
            }
            if (options.type.toUpperCase() === 'GET') {
                // 2.连接
                xhr.open('get', "".concat(options.url, "?").concat(dataToUrlstr), true);
                onStatechange();
                // 3.发送
                xhr.send();
            } else if (options.type.toUpperCase() === 'POST') {
                // console.log(options.data)
                // 2.连接
                xhr.open('post', options.url, true);
                xhr.setRequestHeader('Content-Type', 'application/json');
                // 3.发送
                onStatechange(); //先注册后发送
                xhr.send(JSON.stringify(options.data));
            }
            // 4.接收
            //超时处理
            if (options.timeout) {
                timer = setTimeout(function () {
                    xhr.abort();
                    reject('超时');
                }, options.timeout);
            }
        });
    };
    return WmRequest;
}();
exports.default = WmRequest;