'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
var FadeEffect = /** @class */function () {
    function FadeEffect() {}
    FadeEffect.fadeOut = function (ele, spe, opac) {
        if (spe === void 0) {
            spe = 10;
        }
        if (opac === void 0) {
            opac = 0;
        }
        //透明度从100开始减
        var op = 100;
        // 改变的速度 默认值为20ms
        spe = spe || 10;
        // opac 最终元素的透明度值 默认为0
        opac = opac || 0;
        // 给元素添加样式
        ele.style.opacity = String(opac / 100);
        function changeOpacity() {
            // 给元素添加样式
            ele.style.opacity = String(op / 100);
            // 每次减少的值 可以根据需要调试
            op -= 5;
            if (op >= opac) {
                // 判断值
                setTimeout(changeOpacity, spe); // 每隔spe ms调用一次函数
            } else if (op < 0) {
                // 当小于0 的时候 元素隐藏
                ele.style.display = 'none';
            }
        }
        changeOpacity();
    };
    FadeEffect.fadeIn = function (ele, spe, opac) {
        if (spe === void 0) {
            spe = 10;
        }
        if (opac === void 0) {
            opac = 100;
        }
        //透明度从0开始加
        var op = 0;
        // 改变的速度 默认值为20ms
        spe = spe || 10;
        // opac 最终元素的透明度值 默认为 100
        opac = opac || 100;
        // 给元素添加样式
        ele.style.display = 'block'; //让元素显示
        ele.style.opacity = String(opac / 100);
        function changeOpacity() {
            // 给元素添加样式
            ele.style.opacity = String(op / 100);
            // 每次增加的值 可以根据需要调试
            op += 5;
            if (op <= opac) {
                // 判断值
                setTimeout(changeOpacity, spe); // 每隔spe ms调用一次函数
            }
        }
        changeOpacity();
    };
    return FadeEffect;
}();
exports.default = FadeEffect;