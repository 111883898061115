'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

var _request = require('../../common/request');

var _request2 = _interopRequireDefault(_request);

var _config = require('../../common/config');

var _config2 = _interopRequireDefault(_config);

var _login = require('../login/login');

var _login2 = _interopRequireDefault(_login);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var HeaderMain = /** @class */function () {
    function HeaderMain() {
        this.headerDom = document.getElementById('kingshiper-header');
        this.pathName = window.location.pathname;
        this.wmRequest = new _request2.default();
        this.init();
    }
    HeaderMain.prototype.init = function () {
        this.textActive();
        this.login();
        this.logout();
        this.initChildType();
        this.toggleChildType();
    };
    HeaderMain.prototype.initChildType = function () {
        var _this = this;
        var tabItems = _tool2.default.$_selectAll(this.headerDom, '.kingshiper-header-center-category-name');
        tabItems.forEach(function (item) {
            // 鼠标移入操作,勿删
            // item.addEventListener('mouseenter', () => {
            //   if (!item.classList.contains('active')) {
            //     item.click()
            //   }
            // })
            // item.addEventListener('mouseleave', (event) => {
            //   // 获取鼠标焦点的坐标
            //   var x = event.clientX;
            //   var y = event.clientY;
            //   // 使用elementFromPoint获取鼠标焦点位置的元素
            //   var element = document.elementFromPoint(x, y);
            //   if (element?.classList.contains('kingshiper-header-center-category') || element?.classList.contains('kingshiper-header-content')) {
            //     item.click()
            //   }
            // })
            // const AllShadeDemo = Tool.$_selectAll(this.headerDom, '.shade');
            // AllShadeDemo.forEach(sItem => {
            //   sItem.addEventListener('mouseenter', () => {
            //     if (item.classList.contains('active')) {
            //       item.click()
            //     }
            //   })
            // })
            item.addEventListener('click', function () {
                var childTypeItem = _tool2.default.$_selectAll(_this.headerDom, '.kingshiper-header-category-left-item');
                childTypeItem.forEach(function (cItem) {
                    return cItem.classList.remove('active');
                });
                var activeGoodsUls = _tool2.default.$_selectAll(_this.headerDom, '.kingshiper-header-category-right ul');
                activeGoodsUls.forEach(function (aItem) {
                    return aItem.classList.remove('active');
                });
                var goodsPanel = item.nextElementSibling;
                if (!item.classList.contains('active')) {
                    tabItems.forEach(function (tItem) {
                        return tItem.classList.remove('active');
                    });
                    item.classList.add('active');
                    var goodsPanels = _tool2.default.$_selectAll(_this.headerDom, '.kingshiper-header-center-category-list');
                    goodsPanels.forEach(function (gItem) {
                        return gItem.classList.remove('show');
                    });
                    goodsPanel.classList.add('show');
                } else {
                    item.classList.remove('active');
                    goodsPanel.classList.remove('show');
                }
                var childTypeFirstItem = _tool2.default.$_selectAll(_this.headerDom, '.kingshiper-header-category-left-item:first-child');
                childTypeFirstItem.forEach(function (cItem) {
                    cItem.classList.add('active');
                    var dataAlias = cItem.getAttribute('data-alias');
                    var activeGoodsUl = _tool2.default.$_select(_this.headerDom, ".kingshiper-header-category-right ul.".concat(dataAlias));
                    activeGoodsUl.classList.add('active');
                });
            });
        });
    };
    HeaderMain.prototype.toggleChildType = function () {
        var _this = this;
        var childTypeItem = _tool2.default.$_selectAll(this.headerDom, '.kingshiper-header-category-left-item');
        childTypeItem.forEach(function (item) {
            item.addEventListener('click', function () {
                childTypeItem.forEach(function (cItem) {
                    return cItem.classList.remove('active');
                });
                item.classList.add('active');
                var activeGoodsUls = _tool2.default.$_selectAll(_this.headerDom, ".kingshiper-header-category-right ul");
                activeGoodsUls.forEach(function (aItem) {
                    return aItem.classList.remove('active');
                });
                var dataAlias = item.getAttribute('data-alias');
                var activeGoodsUl = _tool2.default.$_select(_this.headerDom, ".kingshiper-header-category-right ul.".concat(dataAlias));
                activeGoodsUl.classList.add('active');
                // 重置滚动条高度
                var box = document.getElementById('category-right');
                box.scrollTop = 0;
            });
        });
    };
    HeaderMain.prototype.textActive = function () {
        // 一级导航栏文字设置活跃状态
        if (this.pathName.includes('/support')) {
            var activeNav = _tool2.default.$_select(this.headerDom, '.kingshiper-header-center-otherpage a.nav-support');
            activeNav.classList.add('active');
        } else if (this.pathName.includes('/purchase')) {
            var activeNav = _tool2.default.$_select(this.headerDom, '.kingshiper-header-center-otherpage a.nav-purchase');
            activeNav.classList.add('active');
        }
    };
    HeaderMain.prototype.login = function () {
        var that = this;
        // 登录：一级导航栏触发
        var hasLoginBtn = document.body.contains(document.getElementsByClassName('kingshiper-header-right-no-login')[0]);
        if (hasLoginBtn) {
            var loginBtn = _tool2.default.$_select(that.headerDom, '.kingshiper-header-right-no-login');
            loginBtn.addEventListener('click', function () {
                new _login2.default();
            });
        }
        // 政企采购首页登录：页面元素触发
        if (this.pathName == '/purchase') {
            var purchaseDom = document.getElementById('purchase');
            var toPurchasePayBtns = _tool2.default.$_selectAll(purchaseDom, '.toPurchasePay');
            toPurchasePayBtns.forEach(function (item) {
                item.addEventListener('click', function () {
                    var jumpToUrl = item.getAttribute('data-jumpto');
                    if (isLogin == 1) {
                        window.open(jumpToUrl);
                    } else {
                        new _login2.default(jumpToUrl); // 意图前往/purchasePay?g_id=xxx
                    }
                });
            });
            var toExchangeCodeBtn_1 = _tool2.default.$_select(purchaseDom, '.toExchangeCode');
            toExchangeCodeBtn_1.addEventListener('click', function () {
                var jumpToUrl = toExchangeCodeBtn_1.getAttribute('data-jumpto');
                if (isLogin == 1) {
                    window.open(jumpToUrl);
                } else {
                    new _login2.default(jumpToUrl); // 意图前往/ExchangeCode
                }
            });
        }
        // 政企采购购买页or兑换中心登录：进入页面检测是否登录
        if (this.pathName == '/purchasePay' || this.pathName == '/ExchangeCode' || this.pathName.includes('/user')) {
            if (isLogin == 0) {
                new _login2.default();
            }
        }
    };
    HeaderMain.prototype.logout = function () {
        var _this = this;
        var that = this;
        // 退出
        var hasLogOutBtn = document.body.contains(document.getElementsByClassName('kingshiper-header-right-exit')[0]);
        if (hasLogOutBtn) {
            var logOutBtn = _tool2.default.$_select(that.headerDom, '.kingshiper-header-right-exit');
            logOutBtn.addEventListener('click', function () {
                that.wmRequest.ajax({
                    type: 'GET',
                    url: _config2.default.WM_LOGINOUT
                }).then(function (res) {
                    if (res.code == 200) {
                        if (_this.pathName.includes('/user')) {
                            window.location.href = '/';
                        } else if (_this.pathName.includes('/purchasePay')) {
                            window.location.href = '/purchase';
                        } else {
                            window.location.reload();
                        }
                    }
                });
            });
        }
    };
    return HeaderMain;
}();
exports.default = HeaderMain;