'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _loginHtml = require('./loginHtml');

var _loginHtml2 = _interopRequireDefault(_loginHtml);

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

var _fadeEffect = require('../../common/fadeEffect');

var _fadeEffect2 = _interopRequireDefault(_fadeEffect);

var _request = require('../../common/request');

var _request2 = _interopRequireDefault(_request);

var _config = require('../../common/config');

var _config2 = _interopRequireDefault(_config);

var _loginMethodList = require('./loginMethodList');

var _loginMethodList2 = _interopRequireDefault(_loginMethodList);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Login = /** @class */function () {
    function Login(jumpToUrl) {
        var _a, _b;
        // 选择的登录方式，wechat:微信，phone:手机号，email:邮箱
        this.loginFlag = "wechat";
        document.getElementsByTagName("body")[0].insertAdjacentHTML("beforeend", _loginHtml2.default.loginStr);
        this.loginDom = document.getElementById("wmLogin-container");
        this.tabList = (_a = this.loginDom.querySelector(".login-container-tab")) === null || _a === void 0 ? void 0 : _a.getElementsByTagName('li');
        this.bottomList = (_b = this.loginDom.querySelector(".login-bottom")) === null || _b === void 0 ? void 0 : _b.getElementsByTagName('li');
        if (jumpToUrl) {
            this.loginMethod = new _loginMethodList2.default(jumpToUrl);
        } else {
            this.loginMethod = new _loginMethodList2.default();
        }
        this.fullUrl = window.location.href;
        this.wmRequest = new _request2.default();
        this.init();
    }
    Login.prototype.init = function () {
        this.showLastLogin();
        this.topTab();
        this.bottomTab();
        this.defaultEvent();
    };
    Login.prototype.showLastLogin = function () {
        // 上次登录：1.微信 2.手机 3.邮箱 4.QQ
        var lastLoginLabels = _tool2.default.$_selectAll(this.loginDom, '.last-login-method');
        lastLoginLabels.forEach(function (item) {
            return item.classList.remove('show');
        });
        var lastLoginLabel;
        switch (Number(lastLoginMethod)) {
            case 1:
                lastLoginLabel = _tool2.default.$_select(this.loginDom, '.last-login-wx');
                break;
            case 2:
                lastLoginLabel = _tool2.default.$_select(this.loginDom, '.last-login-phone');
                break;
            case 3:
                lastLoginLabel = _tool2.default.$_select(this.loginDom, '.last-login-email');
                break;
            case 4:
                lastLoginLabel = _tool2.default.$_select(this.loginDom, '.last-login-qq');
                break;
        }
        lastLoginLabel.classList.add('show');
    };
    Login.prototype.defaultEvent = function () {
        var that = this;
        that.getWechatQrcode();
        var closeBtn = _tool2.default.$_select(that.loginDom, '.dialog-close');
        // 隐藏关闭按钮，引导用户强制登录
        if (this.fullUrl == '/purchasePay' || this.fullUrl == '/ExchangeCode' || this.fullUrl.includes('/buy?g_id=') || this.fullUrl.includes('/user')) {
            closeBtn.classList.add('hide');
        }
        // 关闭登录弹窗
        closeBtn.addEventListener("click", function () {
            var search = window.location.search;
            if (search.indexOf('useCoupon=1') != -1) {
                var newUrl = window.location.href.replace(search, '');
                history.pushState(null, '', newUrl);
            }
            _fadeEffect2.default.fadeOut(that.loginDom);
            setTimeout(function () {
                that.loginDom.remove();
                that.loginFlag = 'close';
                that.loginMethod.showLoginBox(that.loginFlag);
            }, 500);
        });
    };
    // 获取微信登录二维码
    Login.prototype.getWechatQrcode = function () {
        var that = this;
        var qrcodeDom = _tool2.default.$_select(that.loginDom, ".login-qrcode img");
        that.wmRequest.ajax({
            type: 'GET',
            url: _config2.default.WM_LOGIN_GETWECHATCODE,
            data: {}
        }).then(function (res) {
            if (res.code == 200) {
                qrcodeDom.setAttribute("src", "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=".concat(res.qr_code));
            } else {
                alert("获取二维码失败");
            }
        });
    };
    // 顶部tab栏切换，微信手机号
    Login.prototype.topTab = function () {
        var _loop_1 = function _loop_1(i) {
            var that = this_1;
            that.tabList[i].addEventListener("click", function () {
                for (var j = 0; j < that.tabList.length; j++) {
                    that.tabList[j].classList.remove("active");
                }
                that.tabList[i].classList.add("active");
                that.loginFlag = i == 0 ? "wechat" : "phone";
                that.loginMethod.showLoginBox(that.loginFlag);
            });
        };
        var this_1 = this;
        for (var i = 0; i < this.tabList.length; i++) {
            _loop_1(i);
        }
    };
    //底部按钮点击
    Login.prototype.bottomTab = function () {
        var that = this;
        //qq登录
        that.bottomList[0].addEventListener("click", function () {
            that.loginMethod.qqLogin();
        });
        // 切换邮箱登录
        that.bottomList[1].addEventListener("click", function () {
            for (var j = 0; j < that.tabList.length; j++) {
                that.tabList[j].classList.remove("active");
            }
            that.loginFlag = "email";
            that.loginMethod.showLoginBox(that.loginFlag);
        });
    };
    return Login;
}();
exports.default = Login;