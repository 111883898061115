'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

var _request = require('../../common/request');

var _request2 = _interopRequireDefault(_request);

var _config = require('../../common/config');

var _config2 = _interopRequireDefault(_config);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

//告诉编译器此变量已声明
var LoginMethodList = /** @class */function () {
    function LoginMethodList(jumpToUrl) {
        // 微信轮询定时器
        this.wechatCutDown = 300;
        this.wechatTimer = null;
        //获取手机号验证码倒计时
        this.phoneCutDown = 60;
        this.phoneTimer = null;
        // 获取邮箱号验证码倒计时
        this.emailCutDown = 60;
        this.emailTimer = null;
        // qq号登录
        this.qr_id = "";
        this.qqTimer = null;
        this.getEmailFlag = false;
        this.getPhoneFlag = false;
        this.loginContainer = document.getElementById("login-method-list");
        this.loginDomList = this.loginContainer.querySelectorAll(".login-method-box");
        this.wmRequest = new _request2.default();
        this.jumpToUrl = jumpToUrl;
        this.init(this.jumpToUrl);
    }
    // 初始化
    LoginMethodList.prototype.init = function (jumpToUrl) {
        this.wechatLogin_polling(jumpToUrl);
        this.phoneLoginEventBind(jumpToUrl);
        this.emailLoginEventBind(jumpToUrl);
        this.enterKeyEvent();
        this.wechatEvent();
        // this.listenOnlineEvent();
    };
    // 登陆方式盒子展示
    LoginMethodList.prototype.showLoginBox = function (showFlag) {
        for (var i = 0; i < this.loginDomList.length; i++) {
            this.loginDomList[i].style.display = "none";
        }
        switch (showFlag) {
            case "wechat":
                this.wechatLogin_polling(this.jumpToUrl);
                this.loginContainer.querySelector(".login-list-wechat").style.display = "block";
                break;
            case "phone":
                clearInterval(Number(this.wechatTimer));
                this.loginContainer.querySelector(".login-list-phone").style.display = "block";
                break;
            case "email":
                clearInterval(Number(this.wechatTimer));
                this.loginContainer.querySelector(".login-list-email").style.display = "block";
                break;
            case "close":
                clearInterval(Number(this.wechatTimer));
                break;
        }
    };
    // 网络监听
    // listenOnlineEvent(){
    //     let that = this;
    //     window.addEventListener("offline",function(){
    //         clearInterval(Number(that.wechatTimer));
    //     })
    // }
    //微信登录,轮询
    LoginMethodList.prototype.wechatLogin_polling = function (jumpToUrl) {
        var that = this;
        var qrcodeOverdue = _tool2.default.$_select(that.loginContainer, ".login-qrcode-overdue");
        that.wechatTimer = setInterval(function () {
            if (that.wechatCutDown == 0) {
                qrcodeOverdue.style.display = "flex";
            } else {
                that.wechatCutDown--;
                that.wmRequest.ajax({
                    url: _config2.default.WM_WECHAT_POLLING,
                    type: 'GET',
                    data: {}
                }).then(function (res) {
                    if (res.code == 200) {
                        clearInterval(Number(that.wechatTimer));
                        if (jumpToUrl) {
                            window.location.href = jumpToUrl; // 跳转其他页：如从/purchase登录跳转到/ExchangeCode
                        } else {
                            window.location.reload(); // 原地刷新
                        }
                    } else {}
                }).catch(function (e) {
                    console.log(e);
                    clearInterval(Number(that.wechatTimer));
                });
            }
        }, 1000);
    };
    // 回车键监听
    LoginMethodList.prototype.enterKeyEvent = function () {
        var that = this;
        document.onkeydown = function (event) {
            var _a, _b;
            var e = event || window.event;
            if (e && e.keyCode == 13) {
                //回车键的键值为13
                if (((_a = document.activeElement) === null || _a === void 0 ? void 0 : _a.id) === "phoneCodeInp") {
                    _tool2.default.$_select(that.loginContainer, ".login-phone-btn").click();
                } else if (((_b = document.activeElement) === null || _b === void 0 ? void 0 : _b.id) === "emailCodeInp") {
                    _tool2.default.$_select(that.loginContainer, ".login-email-btn").click();
                }
            }
        };
    };
    // 微信二维码过期刷新页面
    LoginMethodList.prototype.wechatEvent = function () {
        var that = this;
        var qrcodeOverdue = _tool2.default.$_select(that.loginContainer, ".login-qrcode-overdue");
        qrcodeOverdue.addEventListener("click", function () {
            window.location.reload();
        });
    };
    //手机号登录事件集合
    LoginMethodList.prototype.phoneLoginEventBind = function (jumpToUrl) {
        var that = this;
        var loginPhoneDom = that.loginDomList[1];
        var phoneInpDom = _tool2.default.$_select(loginPhoneDom, ".phoneInp");
        var phoneCodeDom = _tool2.default.$_select(loginPhoneDom, ".phoneCodeInp");
        var PhoneCodeBtnDom = _tool2.default.$_select(loginPhoneDom, ".getPhoneCode");
        var phoneInpError = _tool2.default.$_select(loginPhoneDom, ".login-phoneInp-error");
        var phoneCodeError = _tool2.default.$_select(loginPhoneDom, ".login-phoneCode-error");
        var phoneLoginBtn = _tool2.default.$_select(loginPhoneDom, ".login-phone-btn");
        var phoneCutDown = _tool2.default.$_select(loginPhoneDom, ".phoneCodeCutDown");
        // 手机号输入框
        phoneInpDom.addEventListener("input", function () {
            var phoneVal = this.value;
            phoneVal ? PhoneCodeBtnDom.classList.add("active") : PhoneCodeBtnDom.classList.remove("active");
            phoneInpError.innerText = '';
        });
        phoneInpDom.addEventListener("blur", function () {
            var phoneVal = this.value;
            if (!phoneVal) {
                phoneInpError.innerText = _config2.default.Wm_tips_phone_empty;
                return;
            }
            if (!_tool2.default.isPhoneNo(phoneVal)) {
                phoneInpError.innerText = _config2.default.Wm_tips_phone_error;
                return;
            }
        });
        // 获取手机验证码
        PhoneCodeBtnDom === null || PhoneCodeBtnDom === void 0 ? void 0 : PhoneCodeBtnDom.addEventListener("click", function () {
            var phoneVal = phoneInpDom.value;
            if (!phoneVal) {
                phoneInpError.innerText = _config2.default.Wm_tips_phone_empty;
                return;
            }
            if (!_tool2.default.isPhoneNo(phoneVal)) {
                phoneInpError.innerText = _config2.default.Wm_tips_phone_error;
                return;
            }
            that.getPhoneFlag = true;
            //获取验证码请求
            that.wmRequest.ajax({
                url: _config2.default.WM_GETPHONECODE,
                type: 'GET',
                data: {
                    phone: phoneVal
                }
            }).then(function (res) {
                if (res.code == 200) {
                    PhoneCodeBtnDom.style.display = "none";
                    phoneCutDown.style.display = "block";
                    that.phoneTimer = setInterval(function () {
                        that.phoneCutDownEvent();
                    }, 1000);
                }
            });
        });
        // 手机验证码输入框
        phoneCodeDom.addEventListener("input", function () {
            var phoneCodeVal = this.value;
            phoneCodeError.innerHTML = '';
            phoneCodeVal ? phoneLoginBtn.classList.add("login-btn-active") : phoneLoginBtn.classList.remove("login-btn-active");
        });
        // 手机号登录按钮
        phoneLoginBtn === null || phoneLoginBtn === void 0 ? void 0 : phoneLoginBtn.addEventListener("click", function () {
            var _a;
            var phoneVal = phoneInpDom.value;
            var phoneCodeVal = (_a = _tool2.default.$_select(loginPhoneDom, ".phoneCodeInp")) === null || _a === void 0 ? void 0 : _a.value;
            if (!this.classList.contains("login-btn-active")) return;
            if (!phoneVal) {
                phoneInpError.innerText = _config2.default.Wm_tips_phone_empty;
                return;
            }
            if (!_tool2.default.isPhoneNo(phoneVal)) {
                phoneInpError.innerText = _config2.default.Wm_tips_phone_error;
                return;
            }
            if (!that.getPhoneFlag) {
                phoneCodeError.innerText = _config2.default.Wm_tips_getCode;
                return;
            }
            // 手机号登录请求
            that.wmRequest.ajax({
                url: _config2.default.WM_PHONE_LOGIN,
                type: "GET",
                data: {
                    // phone:phoneVal,
                    PhoneCode: phoneCodeVal
                }
            }).then(function (res) {
                if (res.code == 200) {
                    phoneCodeError.innerHTML = '';
                    clearInterval(Number(that.phoneTimer));
                    if (jumpToUrl) {
                        window.location.href = jumpToUrl; // 跳转其他页：如从/purchase登录跳转到/ExchangeCode
                    } else {
                        window.location.reload(); // 原地刷新
                    }
                } else {
                    phoneCodeError.innerHTML = res.msg;
                }
            });
        });
    };
    // 手机号获取验证码倒计时
    LoginMethodList.prototype.phoneCutDownEvent = function () {
        var that = this;
        var loginPhoneDom = that.loginDomList[1];
        var PhoneCodeBtnDom = _tool2.default.$_select(loginPhoneDom, ".getPhoneCode");
        var phoneCutDown = _tool2.default.$_select(loginPhoneDom, ".phoneCodeCutDown");
        var cutDownNum = phoneCutDown.querySelector("span");
        if (that.phoneCutDown == 0) {
            PhoneCodeBtnDom.style.display = "block";
            phoneCutDown.style.display = "none";
            that.phoneCutDown = 60;
            clearInterval(Number(that.phoneTimer));
        } else {
            that.phoneCutDown--;
            cutDownNum.innerText = that.phoneCutDown.toString();
        }
    };
    // 邮箱号登录事件集合
    LoginMethodList.prototype.emailLoginEventBind = function (jumpToUrl) {
        var that = this;
        var loginEmailDom = that.loginDomList[2];
        var emialInpDom = _tool2.default.$_select(loginEmailDom, ".emailInp");
        var emialCodeDom = _tool2.default.$_select(loginEmailDom, ".emailCodeInp");
        var emialCodeBtnDom = _tool2.default.$_select(loginEmailDom, ".getEmailCode");
        var emialInpError = _tool2.default.$_select(loginEmailDom, ".login-emailInp-error");
        var emialCodeError = _tool2.default.$_select(loginEmailDom, ".login-emailCode-error");
        var emialLoginBtn = _tool2.default.$_select(loginEmailDom, ".login-email-btn");
        var emailCutDown = _tool2.default.$_select(loginEmailDom, ".emailCodeCutDown");
        // 邮箱号输入框
        emialInpDom === null || emialInpDom === void 0 ? void 0 : emialInpDom.addEventListener("input", function () {
            var emailVal = this.value;
            emailVal ? emialCodeBtnDom.classList.add("active") : emialCodeBtnDom.classList.remove("active");
            emialInpError.innerText = '';
        });
        emialInpDom === null || emialInpDom === void 0 ? void 0 : emialInpDom.addEventListener("blur", function () {
            var emailVal = this.value;
            if (!emailVal) {
                emialInpError.innerText = _config2.default.Wm_tips_email_empty;
                return;
            }
            if (!_tool2.default.isMail(emailVal)) {
                emialInpError.innerText = _config2.default.Wm_tips_email_error;
                return;
            }
        });
        // 获取邮箱验证码
        emialCodeBtnDom === null || emialCodeBtnDom === void 0 ? void 0 : emialCodeBtnDom.addEventListener("click", function () {
            var _this = this;
            var emailVal = emialInpDom.value;
            if (!emailVal) {
                emialInpError.innerText = _config2.default.Wm_tips_email_empty;
                return;
            }
            if (!_tool2.default.isMail(emailVal)) {
                emialInpError.innerText = _config2.default.Wm_tips_email_error;
                return;
            }
            that.getEmailFlag = true;
            //获取验证码请求
            that.wmRequest.ajax({
                url: _config2.default.WM_GETEMAILCODE,
                type: "GET",
                data: {
                    Email: emailVal
                }
            }).then(function (res) {
                if (res.code == 200) {
                    _this.style.display = "none";
                    emailCutDown.style.display = "block";
                    that.emailTimer = setInterval(function () {
                        that.emailCutDownEvent();
                    }, 1000);
                }
            });
        });
        // 邮箱验证码输入框
        emialCodeDom === null || emialCodeDom === void 0 ? void 0 : emialCodeDom.addEventListener("input", function () {
            var emailVal = this.value;
            emailVal ? emialLoginBtn.classList.add("login-btn-active") : emialLoginBtn.classList.remove("login-btn-active");
        });
        // 邮箱号登录按钮
        emialLoginBtn === null || emialLoginBtn === void 0 ? void 0 : emialLoginBtn.addEventListener("click", function () {
            var _a;
            var emailVal = emialInpDom.value;
            var emailCodeVal = (_a = _tool2.default.$_select(loginEmailDom, ".emailCodeInp")) === null || _a === void 0 ? void 0 : _a.value;
            if (!this.classList.contains("login-btn-active")) return;
            if (!emailVal) {
                emialInpError.innerText = _config2.default.Wm_tips_email_empty;
                return;
            }
            if (!_tool2.default.isMail(emailVal)) {
                emialInpError.innerText = _config2.default.Wm_tips_email_error;
                return;
            }
            if (!that.getEmailFlag) {
                emialCodeError.innerText = _config2.default.Wm_tips_getCode;
                return;
            }
            // 邮箱号登录请求
            that.wmRequest.ajax({
                url: _config2.default.WM_EMAIL_LOGIN,
                type: "GET",
                data: {
                    EmailCode: emailCodeVal
                    // Email:emailVal,
                }
            }).then(function (res) {
                if (res.code == 200) {
                    emialCodeError.innerText = '';
                    clearInterval(Number(that.emailTimer));
                    if (jumpToUrl) {
                        window.location.href = jumpToUrl; // 跳转其他页：如从/purchase登录跳转到/ExchangeCode
                    } else {
                        window.location.reload(); // 原地刷新
                    }
                } else {
                    emialCodeError.innerText = res.msg;
                }
            });
        });
    };
    // 邮箱验证码倒计时
    LoginMethodList.prototype.emailCutDownEvent = function () {
        var that = this;
        var loginEmailDom = that.loginDomList[2];
        var emialCodeBtnDom = _tool2.default.$_select(loginEmailDom, ".getEmailCode");
        var emailCutDown = _tool2.default.$_select(loginEmailDom, ".emailCodeCutDown");
        var cutDownNum = emailCutDown.querySelector("span");
        if (that.emailCutDown == 0) {
            emialCodeBtnDom.style.display = "block";
            emailCutDown.style.display = "none";
            that.emailCutDown = 60;
            clearInterval(Number(that.emailTimer));
        } else {
            that.emailCutDown--;
            cutDownNum.innerText = that.emailCutDown.toString();
        }
    };
    // qq登录
    LoginMethodList.prototype.qqLogin = function () {
        var _this = this;
        window.open(_config2.default.WM_QQ_LOGIN, "_blank");
        this.qqTimer = setInterval(function () {
            _this.qqLogin_polling();
        }, 1000);
    };
    //qq登录轮询
    LoginMethodList.prototype.qqLogin_polling = function (jumpToUrl) {
        var that = this;
        that.wmRequest.ajax({
            url: _config2.default.WM_QQ_POLLING,
            type: "POST",
            data: {}
        }).then(function (res) {
            if (res.code == 200) {
                clearInterval(Number(that.qqTimer));
                if (jumpToUrl) {
                    window.location.href = jumpToUrl; // 跳转其他页：如从/purchase登录跳转到/ExchangeCode
                } else {
                    window.location.reload(); // 原地刷新
                }
            }
        });
    };
    return LoginMethodList;
}();
exports.default = LoginMethodList;