'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _tool = require('../../common/tool');

var _tool2 = _interopRequireDefault(_tool);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SecondaryHeaderMain = /** @class */function () {
    function SecondaryHeaderMain() {
        this.headerDom = document.getElementById('kingshiper-header');
        this.secondaryHeaderDom = document.getElementById('kingshiper-secondary-header');
        this.pathName = window.location.pathname;
        this.init();
    }
    SecondaryHeaderMain.prototype.init = function () {
        this.secondaryHeaderEvent();
    };
    SecondaryHeaderMain.prototype.secondaryHeaderEvent = function () {
        var that = this;
        var hasSecondaryHeader = document.body.contains(document.getElementById('kingshiper-secondary-header'));
        // 适用：操作手册、落地页；不适用：文章详情页
        if (hasSecondaryHeader) {
            // 判断该页面是否存在次级导航栏
            // 一级导航栏和次级导航栏随滚动切换显隐
            document.addEventListener('scroll', function () {
                var scrollH = document.documentElement.scrollTop || document.body.scrollTop;
                if (scrollH > 90) {
                    that.headerDom.classList.add('hide');
                    that.secondaryHeaderDom.classList.add('active');
                } else {
                    that.headerDom.classList.remove('hide');
                    that.secondaryHeaderDom.classList.remove('active');
                }
            });
            // 次级导航栏-循环生成app下载二维码
            var appCodeListDom = _tool2.default.$_selectAll(that.secondaryHeaderDom, '.qr-code-img');
            if (appCodeListDom.length != 0) {
                appCodeListDom.forEach(function (item) {
                    var _val = item.getAttribute('data-dUrl');
                    createQrcode(item, _val);
                });
            }
            // 次级导航栏-文字设置活跃状态
            if (this.pathName.includes('/help.html')) {
                // 操作手册
                var activeNav = _tool2.default.$_select(this.secondaryHeaderDom, '.kingshiper-secondary-header-left-tab a:last-child');
                activeNav.classList.add('active');
            } else if (!this.pathName.includes('/support')) {
                // 落地页
                var activeNav = _tool2.default.$_select(this.secondaryHeaderDom, '.kingshiper-secondary-header-left-tab a:first-child');
                activeNav.classList.add('active');
            }
            // 次级导航栏-全家桶落地页隐藏“操作手册”
            if (this.pathName.includes('/quanburuanjian')) {
                var activeNavs = _tool2.default.$_selectAll(this.secondaryHeaderDom, '.kingshiper-secondary-header-left-tab a:last-child');
                activeNavs.forEach(function (item) {
                    item.classList.add('hide');
                });
            }
            // 次级导航栏-文章详情页滚动显示
            if (this.pathName.includes('/support')) {
                document.addEventListener('scroll', function () {
                    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                    if (scrollTop > 100) {
                        that.secondaryHeaderDom.classList.add('active');
                    } else {
                        that.secondaryHeaderDom.classList.remove('active');
                    }
                });
            }
        }
    };
    return SecondaryHeaderMain;
}();
exports.default = SecondaryHeaderMain;